.MuiTable-root {
  &.custom-table {
    .MuiTableCell-head {
      font-size: 14px;
      font-weight: 700;
      background-color: #f0f0f0;
      padding: 10px;
    }

    .MuiTableCell-body {
      padding: 10px;
    }

    .MuiTableCell-footer {
      font-size: 14px;
      font-weight: 700;
      background-color: #454545;
    }

    .MuiTableRow-root {
      cursor: pointer;

      &:hover {
        background-color: #f9f9f9;
      }
    }

    .MuiCheckbox-root {
      padding: 0;
    }

    &.custom-table-sm {
      .MuiTableCell-body {
        padding: 5px 8px;
      }
    }
  }
}

.MuiPagination-root {
  .MuiPaginationItem-root.Mui-selected {
    font-weight: 700;
  }
}