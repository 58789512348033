.status-badge {
  padding: 0.125em 0.5em;
  font-size: 0.75em;
  border-radius: 0.5em;
  background-color: #ccc;
  color: #000;

  &.primary {
    color: $white;
    background-color: #f93;
  }

  &.secondary {
    color: $white;
    background-color: $secondary;
  }

  &.info {
    color: $white;
    background-color: $info;
  }

  &.success {
    color: $white;
    background-color: $success;
  }

  &.warning {
    color: $white;
    background-color: $warning;
  }

  &.error {
    color: $white;
    background-color: $error;
  }

  &.green {
    color: $white;
    background-color: $green;
  }
}