.MuiFormControlLabel-root {
  margin-left: -7px !important;
}

.MuiRadio-root {
  padding: 4px !important;
}

.MuiCheckbox-root {
  padding: 4px !important;
}

.MuiFormControlLabel-root.activated-checkbox {
  opacity: 0.7;
}

.MuiFormControlLabel-root.activated-checkbox.on {
  opacity: 1;
}