@charset "UTF-8";

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary;
  padding: 12px 16px;
  margin-bottom: 20px;

  .toolbar-shortcuts {
    display: flex;
    align-items: center;
    gap: 0 20px;
    padding: 0;

    .MuiListItem-root {
      padding: 0;

      .MuiListItemIcon-root {
        min-width: 20px;
        margin-right: 4px;
      }

      .MuiListItemText-root {
        margin: 0;
      }

      .MuiTypography-root {
        color: #fff;
        white-space: nowrap;
        font-size: 14px;
      }
    }
  }

  .toolbar-info {
    padding: 0;

    .base-MenuButton-root {
      color: #fff;
      background: desaturate($primary, 30%);
      border: 0;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.toolbar-info-context .base-Menu-listbox {
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 12px 16px;
  margin: 12px 0;
  min-width: 100px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: #fff;
  border: 1px solid $grey-50;
  color: $grey-900;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  z-index: 1;

  a {
    color: $black;
    border-radius: 10px;
  }
}

.base-Popup-root.base-Menu-root.toolbar-info-context {
  z-index: 10;
}
