@charset "UTF-8";

:root {
  @each $key, $value in $grayscale {
    --#{$key}: #{$value};
  }

  @each $key, $value in $theme-colors {
    --#{$key}: #{$value};
  }
}