@charset "UTF-8";

@mixin customScrollbar() {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 5px;
  }
}