@charset "UTF-8";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.loading.visible {
  visibility: visible;
}

.loader {
  &.loader-flash {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #f93;
    box-shadow:
      32px 0 #f93,
      -32px 0 #f93;
    position: relative;
    animation: flash 0.6s linear infinite alternate;
  }

  &.loader-spin {
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
  }

  &.loader-spin2 {
    color: #f93;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin2 1.3s infinite linear;
    transform: translateZ(0);
  }
}

@keyframes flash {
  0% {
    background-color: #f932;
    box-shadow:
      32px 0 #f932,
      -32px 0 #f93;
  }
  50% {
    background-color: #f93;
    box-shadow:
      32px 0 #f932,
      -32px 0 #f932;
  }
  100% {
    background-color: #f932;
    box-shadow:
      32px 0 #f93,
      -32px 0 #f932;
  }
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #f93,
      1.8em -1.8em 0 0em rgba(#f93, 0.2),
      2.5em 0em 0 0em rgba(#f93, 0.2),
      1.75em 1.75em 0 0em rgba(#f93, 0.2),
      0em 2.5em 0 0em rgba(#f93, 0.2),
      -1.8em 1.8em 0 0em rgba(#f93, 0.2),
      -2.6em 0em 0 0em rgba(#f93, 0.5),
      -1.8em -1.8em 0 0em rgba(#f93, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.7),
      1.8em -1.8em 0 0em #f93,
      2.5em 0em 0 0em rgba(#f93, 0.2),
      1.75em 1.75em 0 0em rgba(#f93, 0.2),
      0em 2.5em 0 0em rgba(#f93, 0.2),
      -1.8em 1.8em 0 0em rgba(#f93, 0.2),
      -2.6em 0em 0 0em rgba(#f93, 0.2),
      -1.8em -1.8em 0 0em rgba(#f93, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.5),
      1.8em -1.8em 0 0em rgba(#f93, 0.7),
      2.5em 0em 0 0em #f93,
      1.75em 1.75em 0 0em rgba(#f93, 0.2),
      0em 2.5em 0 0em rgba(#f93, 0.2),
      -1.8em 1.8em 0 0em rgba(#f93, 0.2),
      -2.6em 0em 0 0em rgba(#f93, 0.2),
      -1.8em -1.8em 0 0em rgba(#f93, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.2),
      1.8em -1.8em 0 0em rgba(#f93, 0.5),
      2.5em 0em 0 0em rgba(#f93, 0.7),
      1.75em 1.75em 0 0em #f93,
      0em 2.5em 0 0em rgba(#f93, 0.2),
      -1.8em 1.8em 0 0em rgba(#f93, 0.2),
      -2.6em 0em 0 0em rgba(#f93, 0.2),
      -1.8em -1.8em 0 0em rgba(#f93, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.2),
      1.8em -1.8em 0 0em rgba(#f93, 0.2),
      2.5em 0em 0 0em rgba(#f93, 0.5),
      1.75em 1.75em 0 0em rgba(#f93, 0.7),
      0em 2.5em 0 0em #f93,
      -1.8em 1.8em 0 0em rgba(#f93, 0.2),
      -2.6em 0em 0 0em rgba(#f93, 0.2),
      -1.8em -1.8em 0 0em rgba(#f93, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.2),
      1.8em -1.8em 0 0em rgba(#f93, 0.2),
      2.5em 0em 0 0em rgba(#f93, 0.2),
      1.75em 1.75em 0 0em rgba(#f93, 0.5),
      0em 2.5em 0 0em rgba(#f93, 0.7),
      -1.8em 1.8em 0 0em #f93,
      -2.6em 0em 0 0em rgba(#f93, 0.2),
      -1.8em -1.8em 0 0em rgba(#f93, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.2),
      1.8em -1.8em 0 0em rgba(#f93, 0.2),
      2.5em 0em 0 0em rgba(#f93, 0.2),
      1.75em 1.75em 0 0em rgba(#f93, 0.2),
      0em 2.5em 0 0em rgba(#f93, 0.5),
      -1.8em 1.8em 0 0em rgba(#f93, 0.7),
      -2.6em 0em 0 0em #f93,
      -1.8em -1.8em 0 0em rgba(#f93, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(#f93, 0.2),
      1.8em -1.8em 0 0em rgba(#f93, 0.2),
      2.5em 0em 0 0em rgba(#f93, 0.2),
      1.75em 1.75em 0 0em rgba(#f93, 0.2),
      0em 2.5em 0 0em rgba(#f93, 0.2),
      -1.8em 1.8em 0 0em rgba(#f93, 0.5),
      -2.6em 0em 0 0em rgba(#f93, 0.7),
      -1.8em -1.8em 0 0em #f93;
  }
}

@keyframes mulShdSpin2 {
  0%,
  100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }
  12.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0em,
      -2em 2em 0 -1em,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0em,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow:
      0em -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0.2em,
      -2em -2em 0 0;
  }
  87.5% {
    box-shadow:
      0em -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}
