.MuiPaper-root.custom-paper {
  padding: rem(24);
  box-shadow: 0 0 10px 5px rgba(0,0,0,0.2);
  width: 100%;
  height: 67vh;
  max-height: 67vh;
  overflow-y: auto;

  &.custom-paper-sm {
    height: 55vh;
    max-height: 55vh;
  }

  &.custom-paper-full-height {
    height: 82vh;
    max-height: 82vh;
  }
  &.custom-paper-full-height-90 {
    height: 90vh;
    max-height: 90vh;
  }

  & .MuiGrid-container + .MuiGrid-container {
    margin-top: 8px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 5px;
  }
}

.MuiPaper-root.table-paper {
  padding: 0;

  .MuiTableContainer-root {
    max-height: 100%;
  }
}

.MuiTableContainer-root {
  @include customScrollbar();
}