.gds-request {
  &::before {
    display: inline-block;
    content: '>';
    margin-right: 0.5rem;
  }
}

.gds-answer {
  font-weight: 600;
}

.gds-input.MuiTextField-root {
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
  width: 100%;

  &::before {
    display: inline-block;
    content: '>';
    margin-right: 0.5rem;
  }

  .MuiInputBase-root {
    width: 100%;
  }
}