.chatbot-header {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}

.chatbot-content {
  height: 68vh;
  overflow-y: auto;
  padding: 24px 20px;
  @include customScrollbar;

  .chat-wrap + .chat-wrap {
    margin-top: 6px;
  }

  .chat-wrap.bot-chat + .chat-wrap.my-chat,
  .chat-wrap.my-chat + .chat-wrap.bot-chat {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .chat-box {
    display: flex;
    align-items: flex-end;
    gap: 0 8px;

    .text-balloon {
      padding: 10px 16px;
      color: #222;
      background-color: #fff5eb;
      border-radius: 0 20px 20px 20px;
    }
    .loading-dots {
      display: inline-block;
      font-size: 16px;
      letter-spacing: 2px;
    }

    .loading-dots::after {
      content: '';
      display: inline-block;
      width: 1.5em;
      text-align: left;
      animation: dots 1s steps(5, end) infinite;
    }

    @keyframes dots {
      0%,
      20% {
        content: '';
      }
      40% {
        content: '.';
      }
      60% {
        content: '..';
      }
      80%,
      100% {
        content: '...';
      }
    }

    .date {
      font-size: 11px;
      color: #999;
    }
  }

  .bot-chat {
    flex-direction: row-reverse;

    .chat-box {
      justify-content: flex-end;

      .text-balloon {
        background-color: #f0f0f0;
        border-radius: 20px 0 20px 20px;
      }
    }
  }
}

.chatbot-footer {
  display: flex;
  align-items: stretch;
  padding: 8px 20px 20px;
  border-top: 1px solid #f0f0f0;

  .MuiFormControl-root {
    flex: 1 1 1%;
    width: 1%;
  }

  .MuiButton-root {
    padding: 0;

    &:hover,
    &:focus {
      background: none;
    }
  }
}

.floating-chat {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;

  &-device {
    width: 340px;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    overflow: hidden;
    border-radius: 35px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s ease-in-out;
    position: absolute;
    bottom: 80px;
    right: 0;
    transform: translateY(20px);

    .chatbot-content {
      max-height: 60vh;
    }

    .chat-box {
      font-size: 14px;
      .text-balloon {
        overflow-wrap: break-word;
        max-width: 265px;
      }
      .loading-dots {
        display: inline-block;
        font-size: 16px;
        letter-spacing: 2px;
      }

      .loading-dots::after {
        content: '';
        display: inline-block;
        width: 1.5em;
        text-align: left;
        animation: dots 1s steps(5, end) infinite;
      }

      @keyframes dots {
        0%,
        20% {
          content: '';
        }
        40% {
          content: '.';
        }
        60% {
          content: '..';
        }
        80%,
        100% {
          content: '...';
        }
      }
    }
  }

  .chatbot-footer {
    .MuiInputBase-input {
      font-size: 14px;
    }

    .MuiButton-root {
      min-width: 40px;
      margin-left: 4px;
    }
  }

  &.on {
    .floating-chat-device {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}

.btn-floating-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: $primary;
  color: #fff;
  cursor: pointer;
}
