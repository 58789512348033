.fc {
  // Cells
  td {
    height: 10px;
  }

  // Head
  .fc-scrollgrid-section-header {
    .fc-scroller {
      @include customScrollbar();
    }

    .fc-col-header {
      width: 100% !important;
    }
  }

  // Body
  .fc-scrollgrid-section-body {
    .fc-scroller {
      @include customScrollbar();
    }

    .fc-daygrid-body,
    .fc-scrollgrid-sync-table {
      width: 100% !important;
    }
  }

  // Button
  .fc-button-primary {
    background-color: $primary;
    border-color: $primary;
    transition: all .15s ease-in-out;

    &:disabled {
      background-color: $primary;
      border-color: $primary;
      opacity: 0.5;
    }

    &:hover {
      background-color: #fff;
      border-color: $primary;
      color: $primary;
    }

    &:not(:disabled).fc-button-active,
    &:not(:disabled):active {
      background-color: #fff;
      border-color: $primary;
      color: $primary;
    }

    &:focus,
    &:focus-visible {
      box-shadow: rgba($primary, 0.5) 0 0 0 0.2rem;
    }
  }

  // Toolbar
  .fc-toolbar.fc-header-toolbar {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;

    & > * > :not(:first-child) {
      margin: 0;
    }

    .fc-toolbar-chunk {
      &:nth-child(1) {
        flex: 1 1 1%;
        order: 2;

        .fc-today-button {
          font-size: 0.875em;
        }
      }

      &:nth-child(2) {
        flex: 1 0 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(12);
        order: 1;

        .fc-button {
          padding: 0.1em;
          height: auto;
          line-height: 1;

          .fc-icon {
            font-size: 1.125em;
          }
        }

        .fc-toolbar-title {
          flex: 0 0 15rem;
          text-align: center;
        }
      }

      &:nth-child(3) {
        flex: 1 1 1%;
        order: 3;
      }
    }

    .MuiFormGroup-root {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      gap: 0 rem(12);

      .MuiFormControlLabel-root {
        margin: 0;
        gap: 0 rem(4);

        .MuiButtonBase-root {
          padding: 0;

          .MuiSvgIcon-root {
            width: rem(20);
            height: rem(20);
          }
        }

        .MuiTypography-root {
          font-size: rem(14);
          font-weight: 700;
        }
      }
    }
  }

  // Day Top
  .fc-daygrid-day-top {
    .fc-daygrid-day-top-box {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 0 rem(8);

      &.holiday {
        color: #ed1c24;
      }

      &.sunday {
        color: #ed1c24;
      }

      &.saturday {
        color: #005aff;
      }
    }

    .fc-daygrid-day-text {
      font-weight: 500;
    }

    .fc-daygrid-day-holiday {
      font-size: rem(14);
    }
  }

  // Day Frame
  .fc-daygrid-day-frame {
    padding: rem(4);
  }

  // Day Event
  .fc-daygrid-day-events {
    .event-content {
      display: flex;
      flex-wrap: wrap;
      gap: rem(4);

      .btn {
        border: 0;
        flex: 1 1;
        background: none;
      }
    }
  }

  // Day Bottom
  .fc-daygrid-day-bottom {
    &::before,
    &::after {
      display: none;
    }

    .count-buttons {
      display: flex;
      gap: rem(4);
      flex-wrap: wrap;
    }

    .MuiButtonBase-root {
      flex: 1 0 45%;
      width: 1%;
      padding: 0;
      font-size: rem(12);
      line-height: 1.6;
    }
  }
}