$blue-50: #f0f7ff;
$blue-100: #c2e0ff;
$blue-200: #99ccf3;
$blue-300: #66b2ff;
$blue-400: #39f;
$blue-500: #007fff;
$blue-600: #0072e6;
$blue-700: #0059b3;
$blue-800: #004c99;
$blue-900: #003a75;

$green: #618b25;

$grey-50: #f3f6f9;
$grey-100: #e5eaf2;
$grey-200: #dae2ed;
$grey-300: #c7d0dd;
$grey-400: #b0b8c4;
$grey-500: #9da8b7;
$grey-600: #6b7a90;
$grey-700: #434d5b;
$grey-800: #303740;
$grey-900: #1c2025;

$grayscale: (
  'grey-50': $grey-50,
  'grey-100': $grey-100,
  'grey-200': $grey-200,
  'grey-300': $grey-300,
  'grey-400': $grey-400,
  'grey-500': $grey-500,
  'grey-600': $grey-600,
  'grey-700': $grey-700,
  'grey-800': $grey-800,
  'grey-900': $grey-900
) !default;

$primary: #f93;
$secondary: #121212;
$info: #206a5d;
$success: #009933;
$warning: #ff9f43;
$error: #e01b1b;
$white: #fff;
$black: #000;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'info': $info,
  'success': $success,
  'warning': $warning,
  'error': $error,
  'white': $white,
  'black': $black,
) !default;