/* 특정 요소(예: TableContainer)에 적용하는 스크롤바 스타일 */
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 5px;
  }
}